export function isLoggedIn() {
    return !!getCode();
}

export function getCode() {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code') || localStorage.getItem('tdCode');
    if (code) {
        localStorage.setItem('tdCode', code);
    }
    return code;
}

export function round(number: number, decimals: number) {
    return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export function ding() {
    const ding = new Audio('./ding.mp3');
    ding.play();
}

export function say(message: string) {
    if (!('speechSynthesis' in window)) {
        return ding();
    }
    const voices = window.speechSynthesis.getVoices();
    const voice =
        voices.find((v) => v.name === 'Microsoft Zira - English (United States)') || voices.find((v) => v.default);
    if (!voice) {
        return ding();
    }

    var voiceMessage = new SpeechSynthesisUtterance();
    voiceMessage.text = message;
    voiceMessage.voice = voice;
    window.speechSynthesis.speak(voiceMessage);
}

export function percentChange(a: number, b: number) {
    if (!a) {
        return NaN;
    }
    return (b - a) / a;
}
