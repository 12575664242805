import React from 'react';

import { Box, Flex } from '@chakra-ui/layout';
import { Watchlist } from './Watchlist';

export function Halts() {
    return (
        <Flex>
            {/* <Box>
                <Heading size="sm" p={2}>
                    Halts
                </Heading>
            </Box> */}
            <Box>
                <Watchlist />
            </Box>
        </Flex>
    );
}
