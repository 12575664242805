import { round } from 'lodash';
import moment from 'moment';
import { Trade } from './api/tosClient';
import { getPositions, getPositionShares } from './components/PositionsTable';

export function holdTimeString(trades: Trade[]) {
    const isOpenPosition = getPositionShares(trades) > 0;
    const sorted = trades.sort((a, b) => a.date.getTime() - b.date.getTime());
    const open = sorted[0].date;
    const close = isOpenPosition ? new Date() : sorted[sorted.length - 1].date;
    const timeDiff = moment(close).diff(moment(open)) / 1000;
    const minutes = timeDiff >= 60 ? `${Math.floor(timeDiff / 60)}m` : '';
    const seconds = timeDiff % 60 ? `${Math.floor(timeDiff % 60)}s` : '';
    return `${minutes} ${seconds}`;
}

export function holdTime(trades: Trade[]) {
    const sorted = trades.sort((a, b) => a.date.getTime() - b.date.getTime());
    const open = sorted[0];
    const close = sorted[sorted.length - 1];
    return moment(close.date).diff(moment(open.date)) / 1000;
}

export function getWinsAndLosses(trades: Trade[], type: 'amount' | 'percent' = 'amount') {
    const positions = getPositions(trades);
    return positions.reduce(
        (acc, position) => {
            const profit = type === 'amount' ? profitAmount(position) : profitPercent(position);
            if (profit > 0) {
                acc.numWon++;
                acc.amountWon += profit;
            } else if (profit <= 0) {
                acc.numLost++;
                acc.amountLost += Math.abs(profit);
            }
            return acc;
        },
        { numWon: 0, numLost: 0, amountWon: 0, amountLost: 0 },
    );
}

export function accuracy(trades: Trade[]) {
    const { numWon, numLost } = getWinsAndLosses(trades);

    return numWon / (numLost + numWon);
}

export function average(numbers: number[]) {
    if (!numbers || numbers.length === 0) {
        return null;
    }
    const total = numbers.reduce((sum, num) => sum + num, 0);

    if (!total) {
        return null;
    }

    return total / numbers.length;
}

export function averagePrice(trades: Trade[]) {
    return (average(trades.map((t) => t.price * t.qty)) as number) / (average(trades.map((t) => t.qty)) as number);
}

export function averagePositionSize(trades: Trade[]) {
    const positions = getPositions(trades, true);
    const positionSizes = positions.map((position) =>
        position.filter((t) => t.side === 'BUY').reduce((size, t) => t.qty * t.price + size, 0),
    );
    return average(positionSizes);
}

export function averageSizeDollars(trades: Trade[]) {
    return average(trades.map((t) => t.price * t.qty));
}

export function plRatio(trades: Trade[], type: 'amount' | 'percent' = 'amount') {
    const { amountWon, numWon, amountLost, numLost } = getWinsAndLosses(trades, type);

    return amountWon / numWon / (amountLost / numLost);
}

export function profitAmount(trades: Trade[]) {
    return trades.reduce((profit, t) => (t.side === 'BUY' ? -1 : 1) * t.qty * t.price + profit, 0);
}

function profitPercentSumPositions(trades: Trade[]) {
    const positions = getPositions(trades);

    return positions.map((p) => profitPercent(p)).reduce((profits, profit) => profit + profits, 0);
}

export function profitPercentByTradeSize(trades: Trade[]) {
    const profit = profitAmount(trades);
    const averageTradeSize = average(trades.filter((t) => t.side === 'BUY').map((t) => t.qty * t.price)) as number;

    return profit / averageTradeSize;
}

export function profitPercentByPositionSize(trades: Trade[]) {
    const profit = profitAmount(trades);
    const averagePositionBoughtAmount = averagePositionSize(trades) as number;

    return profit / averagePositionBoughtAmount;
}

export function profitPercent(
    trades: Trade[],
    type: 'positionSize' | 'tradeSize' | 'sumPositions' = 'positionSize',
): number {
    if (type === 'sumPositions') {
        return profitPercentSumPositions(trades);
    } else if (type === 'tradeSize') {
        return profitPercentByTradeSize(trades);
    } else {
        return profitPercentByPositionSize(trades);
    }
}

export function formatDateTime(date: Date) {
    return moment(date).format('M/D/YY h:mm:ss');
}

export function formatCurrency(amount: number, maxDecimals: number = 4) {
    const [dollars] = Math.abs(amount).toString().split('.');
    const dollarSignificantDigits = dollars !== '0' ? dollars.length : 0;
    const maximumSignificantDigits = dollarSignificantDigits + maxDecimals;
    return new Intl.NumberFormat('en-US', {
        maximumSignificantDigits,
        minimumSignificantDigits: maximumSignificantDigits,
        style: 'currency',
        currency: 'USD',
    }).format(amount);
}

export function formatPercent(percent: number) {
    if (isNaN(percent)) {
        return '--';
    }
    return `${round(percent * 100, 2)}%`;
}
