import React from 'react';
import { TableContainer, Table, Tr, Td, Tbody, Th, Thead } from '@chakra-ui/table';

import { Box, Heading, Flex, Spacer } from '@chakra-ui/layout';
import { useAppStore } from '../store';
import { formatCurrency, formatPercent } from '../metrics';
import { Input } from '@chakra-ui/input';
import { Button, IconButton } from '@chakra-ui/button';
import _ from 'lodash';
import { CloseIcon } from '@chakra-ui/icons';
import { percentChange } from '../utils';

export function Watchlist() {
    const [value, setValue] = React.useState('');

    const options = useAppStore((state) => state.options);
    const quotes = useAppStore((state) => state.quotes);
    const watchlist = useAppStore((state) => state.watchlist);
    const setWatchlist = useAppStore((state) => state.setWatchlist);
    const todaysSymbols = useAppStore((state) => state.todaysSymbols);

    const onChange = (e: any) => setValue((e.target.value || '').toUpperCase());
    const onAdd = () => {
        if (!value || !value.length) {
            return;
        }
        setValue('');
        setWatchlist([...watchlist, value]);
    };
    const onRemove = (symbol: string) => setWatchlist(_.without(watchlist, symbol));

    const WatchlistRow = ({ symbol, canDelete }: { symbol: string; canDelete: boolean }) => {
        const quote = quotes.find((q) => q.symbol === symbol);
        return (
            <Tr key={symbol}>
                <Td fontWeight={500}>{symbol}</Td>
                <Td>{quote?.securityStatus || '--'}</Td>
                <Td isNumeric>{quote ? formatCurrency(quote.lastPrice, parseInt(options.currencyDecimals)) : '--'}</Td>
                <Td isNumeric>{quote ? formatPercent(percentChange(quote.closePrice, quote.lastPrice)) : '--'}</Td>
                <Td isNumeric>
                    {quote && options.maxPosition ? Math.floor(options.maxPosition / quote.lastPrice) : '--'}
                </Td>
                <Td>
                    {canDelete && (
                        <IconButton
                            size="xs"
                            colorScheme="red"
                            aria-label="Delete"
                            icon={<CloseIcon h={4} />}
                            onClick={() => onRemove(symbol)}
                        />
                    )}
                </Td>
            </Tr>
        );
    };

    return (
        <Flex>
            <Box>
                <Flex p="2" alignItems="center">
                    <Heading size="sm">Watchlist</Heading>
                    <Spacer />
                    <Box>
                        <Input
                            size="xs"
                            w={16}
                            mr={1}
                            value={value}
                            onChange={onChange}
                            onKeyDown={(e) => (e.key === 'Enter' ? onAdd() : undefined)}
                        />
                        <Button size="xs" colorScheme="green" onClick={onAdd}>
                            Add
                        </Button>
                    </Box>
                </Flex>
                {/* <Heading size="sm" p={2}>
                    Watchlist
                </Heading>
                <Input
                    size="xs"
                    w={16}
                    value={value}
                    onChange={onChange}
                    onKeyDown={(e) => (e.key === 'Enter' ? onAdd() : undefined)}
                />
                <Button size="xs" onClick={onAdd}>
                    Add
                </Button> */}
                <TableContainer>
                    <Table size="sm" colorScheme="cyan" variant="striped">
                        <Thead>
                            <Tr>
                                <Th>Symbol</Th>
                                <Th>Status</Th>
                                <Th>Last Price</Th>
                                <Th>Gap</Th>
                                <Th>Max Position (shares)</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {watchlist
                                .filter((symbol) => !todaysSymbols.find((s) => s === symbol))
                                .map((symbol) => (
                                    <WatchlistRow key={symbol + 'watchlist'} symbol={symbol} canDelete={true} />
                                ))}
                            {todaysSymbols.map((symbol) => (
                                <WatchlistRow key={symbol + 'today'} symbol={symbol} canDelete={false} />
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Flex>
    );
}
