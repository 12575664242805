import React from 'react';
import { Button } from '@chakra-ui/button';
import { Grid, GridItem, Heading, Stack } from '@chakra-ui/layout';
import {
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
} from '@chakra-ui/popover';
import { Radio, RadioGroup } from '@chakra-ui/radio';

import { useAppStore } from '../store';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input';
import { Tooltip } from '@chakra-ui/tooltip';

export interface Options {
    profit: 'positionSize' | 'tradeSize' | 'sumPositions';
    plRatio: 'amount' | 'percent';
    currencyDecimals: '2' | '4';
    maxPosition: number | null;
}

const radios = {
    profit: {
        name: 'Profit (%) Calculation',
        options: [
            {
                key: 'Position Size',
                value: 'positionSize',
                more: 'Total profits ($) against your average bought position size ($).  Default option',
            },
            { key: 'Trade Size', value: 'tradeSize', more: 'Total profits ($) against your average buy trade ($)' },
            {
                key: 'Sum Positions',
                value: 'sumPositions',
                more: "Sum positions' profit percentages, regardless of share price or size.  May be useful if you're learning, and want to gauge performance by each position taken.",
            },
        ],
    },
    plRatio: {
        name: 'P/L Ratio Calculation',
        options: [
            { key: 'Amount Profit', value: 'amount' },
            { key: 'Percent Profit', value: 'percent' },
        ],
    },
    currencyDecimals: {
        name: '$ Decimals',
        options: [
            {
                key: '2 (ex: $12.34)',
                value: '2',
            },
            {
                key: '4 (ex: $12.3456)',
                value: '4',
            },
        ],
    },
};

let latestMaxPositionUpdate = 0;
const OptionsInputs = () => {
    const options = useAppStore((state) => state.options);
    const setOptions = useAppStore((state) => state.setOptions);

    const onChangeRadio = (key: string, value: string) => {
        const updatedOptions = { ...options, [key]: value };
        setOptions(updatedOptions);
    };

    const [maxPositionString, setMaxPositionString] = React.useState(
        options.maxPosition ? options.maxPosition.toString() : '',
    );

    const onChangePositionSize = (str: string) => {
        // Update the local string value and convert it to a number
        setMaxPositionString(str);
        const maxPosition = parseInt(str);
        if (isNaN(maxPosition) || !maxPosition) {
            return;
        }
        // Update the options 500ms after last update
        const updateTime = (latestMaxPositionUpdate = Date.now());
        setTimeout(() => {
            if (updateTime === latestMaxPositionUpdate) {
                setOptions({ ...options, maxPosition });
            }
        }, 300);
    };
    return (
        <Grid>
            {Object.keys(radios).map((key) => {
                const input = (radios as any)[key];
                return (
                    <GridItem key={key} style={{ display: 'inline-block', marginRight: '2em' }}>
                        <Heading size="xs">{input.name}</Heading>
                        <RadioGroup
                            ml="2"
                            size="sm"
                            key={key}
                            value={(options as any)[key]}
                            onChange={(value) => onChangeRadio(key, value)}
                        >
                            <Stack>
                                {input.options.map((option: any) => (
                                    <Radio key={option.key} alt={option.more} fontSize="sm" value={option.value}>
                                        <Tooltip label={option.more}>{option.key}</Tooltip>
                                    </Radio>
                                ))}
                            </Stack>
                        </RadioGroup>
                    </GridItem>
                );
            })}
            <GridItem>
                <Heading size="xs">Max Position Size</Heading>
                <InputGroup w={48}>
                    <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" />
                    <Input
                        placeholder="Enter amount"
                        type="number"
                        value={maxPositionString}
                        onChange={(e) => onChangePositionSize(e.target.value)}
                    />
                </InputGroup>
            </GridItem>
        </Grid>
    );
};

export function OptionsComponent() {
    return (
        <Popover>
            <PopoverTrigger>
                <Button size="sm">Options</Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Options</PopoverHeader>
                <PopoverBody>
                    <OptionsInputs />
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}
