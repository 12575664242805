import React from 'react';
import { PerformanceTable } from './PerformanceTable';
import { PositionsTable } from './PositionsTable';
import { OptionsComponent } from './Options';
import { Box, Flex, Heading, Spacer, Stack } from '@chakra-ui/layout';
import { CalendarFilter } from './CalendarFilter';
import { ButtonGroup } from '@chakra-ui/button';
import { Halts } from './Halts';

export const Main = () => (
    <Stack direction="column">
        {/* <Heading size="sm" p="2">
            Halts
        </Heading> */}
        <Halts />
        <Flex p="1" alignItems="flex-end">
            <Box>
                <Heading p="1" size="sm">
                    Performance
                </Heading>
            </Box>
            <Spacer />
            <ButtonGroup gap="2">
                <CalendarFilter />
                <OptionsComponent />
            </ButtonGroup>
        </Flex>
        <PerformanceTable />
        <Heading size="sm" p="2">
            Positions
        </Heading>
        <PositionsTable />
    </Stack>
);
