import axios from 'axios';
import moment from 'moment';

import { TosClientAuth } from './tosClientAuth';

export interface Order {
    closeTime: string;
    filledQuantity: number;
    orderId: number;
    orderType: 'LMT' | 'MKT' | 'STP';
    orderLegCollection: {
        instruction: 'BUY' | 'SELL';
        instrument: { assetType: string; cusip: string; symbol: string };
    }[];
    orderActivityCollection: {
        executionLegs: { price: number; quantity: number }[];
    }[];
    childOrderStrategies: Order[];
    status: string; // FILLED, CANCELED, REJECTED, etc; Canceled doesn't mean it didn't fill anything!
}

export interface Trade {
    date: Date;
    side: 'BUY' | 'SELL';
    qty: number;
    symbol: string;
    price: number;
    orderType: 'LMT' | 'MKT' | 'STP';
    orderId: number;
}

export interface Quote {
    symbol: string;
    securityStatus: string;
    lastPrice: number;
    closePrice: number;
}

export interface PriceHistory {
    candles: { open: number; close: number; high: number; low: number; volume: number; datetime: number }[];
    symbol: string;
}

export function mapOrdersToTrades(orders: Order[]): Trade[] {
    const trades: Trade[] = [];

    const mapOrderToTrade = (order: Order): Trade => {
        const allOrderExecutionLegs = order.orderActivityCollection.reduce(
            (legs, orderActivity) => [...legs, ...orderActivity.executionLegs],
            [] as { price: number; quantity: number }[],
        );

        const execution = allOrderExecutionLegs.reduce(
            (acc, leg) => {
                acc.price += leg.price * leg.quantity;
                acc.qty += leg.quantity;
                return acc;
            },
            { qty: 0, price: 0 },
        );
        execution.price = execution.price / execution.qty;

        return {
            date: new Date(order.closeTime),
            side: order.orderLegCollection[0].instruction,
            qty: execution.qty,
            symbol: order.orderLegCollection[0].instrument.symbol,
            price: execution.price,
            orderType: order.orderType,
            orderId: order.orderId,
        };
    };

    const filteredOrders = orders.filter(
        (order) =>
            (order.filledQuantity > 0 && order.orderActivityCollection && order.orderActivityCollection.length) ||
            (order.childOrderStrategies && order.childOrderStrategies.length),
    );

    filteredOrders.forEach((order) => {
        if (order.childOrderStrategies && order.childOrderStrategies.length) {
            order.childOrderStrategies
                .filter((o) => o.filledQuantity > 0)
                .forEach((childOrder) => trades.push(mapOrderToTrade(childOrder)));
        } else {
            trades.push(mapOrderToTrade(order));
        }
    });

    return trades;
}

class TosClient extends TosClientAuth {
    protected async onInit() {}

    async getOrders(days: number, offset: number = 0): Promise<Order[]> {
        const url = 'https://api.tdameritrade.com/v1/orders';
        const params = new URLSearchParams();
        const fromEnteredTime = moment()
            .subtract(days + offset, 'days')
            .format('yyyy-MM-DD');
        const toEnteredTime = moment().subtract(offset, 'days').format('yyyy-MM-DD');
        // params.append("status", "FILLED");
        params.append('fromEnteredTime', fromEnteredTime);
        params.append('toEnteredTime', toEnteredTime);
        const res = await axios.get(`${url}?${params}`, {
            ...axios.defaults,
            headers: { Authorization: `Bearer ${await this.getAccessToken()}` },
        });
        return res.data;
    }

    async getTrades(days: number): Promise<Trade[]> {
        return mapOrdersToTrades(await this.getOrders(days));
    }

    async getQuote(symbol: string): Promise<any> {
        const url = `https://api.tdameritrade.com/v1/marketdata/${symbol}/quotes`;
        const res = await axios.get(url, {
            ...axios.defaults,
            headers: { Authorization: `Bearer ${await this.getAccessToken()}` },
        });
        return res.data;
    }

    async getQuotes(symbols: string[]): Promise<Quote[]> {
        const url = `https://api.tdameritrade.com/v1/marketdata/quotes?symbol=${symbols.join(',')}`;
        const res = await axios.get(url, {
            ...axios.defaults,
            headers: { Authorization: `Bearer ${await this.getAccessToken()}` },
        });
        return Object.keys(res.data).map((symbol) => res.data[symbol]);
    }

    async getPriceHistory(symbol: string): Promise<PriceHistory> {
        const url = `https://api.tdameritrade.com/v1/marketdata/${symbol}/pricehistory`;
        const params = new URLSearchParams();
        const fiveMinutesAgo = (Date.now() - 300_000).toString();
        params.append('startDate', fiveMinutesAgo);
        params.append('endDate', Date.now().toString());
        params.append('frequencyType', 'minute');
        params.append('needExtendedHoursData', 'false');
        const res = await axios.get(url, {
            ...axios.defaults,
            headers: { Authorization: `Bearer ${await this.getAccessToken()}` },
            params,
        });
        return res.data;
    }
}

export const tosClient = new TosClient();
