import axios from 'axios';
import moment from 'moment';

const TD_REFRESH_TOKEN = 'tdRefreshToken';
const TD_REFRESH_TOKEN_EXPIRATION = 'tdRefreshTokenExpiration';
const TD_CODE = 'tdCode';
const TD_ACCESS_TOKEN = 'tdAccessToken';
const TD_ACCESS_TOKEN_EXPIRATION = 'tdAccessTokenExpiration';

export abstract class TosClientAuth {
    private _consumerKey: string | null = null;

    private get consumerKey() {
        this._consumerKey = this._consumerKey || localStorage.getItem('tdConsumerKey');
        return this._consumerKey;
    }

    private get redirectUri() {
        return window.location.origin;
    }

    public init: Promise<boolean>;
    constructor() {
        this.init = new Promise(async (resolve) => {
            const accessToken = await this.getAccessToken();
            this.onInit();
            resolve(!!accessToken);
        });
    }

    protected async onInit() {}

    private async getRefreshToken() {
        // Retrieve existing refresh token
        const refreshToken = localStorage.getItem(TD_REFRESH_TOKEN);
        const refreshTokenExpiration = parseInt(localStorage.getItem(TD_REFRESH_TOKEN_EXPIRATION) || '');
        if (refreshToken && refreshTokenExpiration > Date.now()) {
            return refreshToken;
        }

        // Or create a new refresh token
        return this.createRefreshToken();
    }

    async getAccessToken() {
        const refreshToken = await this.getRefreshToken();

        // Retrieve existing access token
        const accessToken = localStorage.getItem(TD_ACCESS_TOKEN);
        const accessTokenExpiration = parseInt(localStorage.getItem(TD_ACCESS_TOKEN_EXPIRATION) || '');
        if (accessToken && accessTokenExpiration > Date.now()) {
            return accessToken;
        }

        // Or create a new access token if we have a refresh token
        if (refreshToken) {
            return this.createAccessToken();
        }

        // Or return nothing if we can't get one
        return null;
    }

    private async createAccessToken(): Promise<string | null> {
        // Check if we have a refresh token
        const refreshToken = await this.getRefreshToken();
        if (!refreshToken) {
            return null;
        }

        try {
            // Request a new access token
            const params = new URLSearchParams();
            params.append('grant_type', 'refresh_token');
            params.append('refresh_token', refreshToken);
            params.append('client_id', this.clientId);
            const res = await axios.post('https://api.tdameritrade.com/v1/oauth2/token', params, {
                ...axios.defaults,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            });

            // Save the token and expiration
            const { access_token, expires_in } = res.data;
            localStorage.setItem(TD_ACCESS_TOKEN, access_token);
            localStorage.setItem(
                TD_ACCESS_TOKEN_EXPIRATION,
                moment().add(expires_in, 'seconds').toDate().getTime().toString(),
            );
            return access_token;
        } catch (ex) {
            localStorage.removeItem(TD_REFRESH_TOKEN);
            return null;
        }
    }

    private async createRefreshToken(): Promise<string | null> {
        const code = this.getCode();
        if (!code) {
            return null;
        }
        try {
            // Request a new access token
            const params = new URLSearchParams();
            params.append('grant_type', 'authorization_code');
            params.append('access_type', 'offline');
            params.append('client_id', this.clientId);
            params.append('redirect_uri', this.redirectUri);
            params.append('code', code);
            const res = await axios.post('https://api.tdameritrade.com/v1/oauth2/token', params, {
                ...axios.defaults,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            });

            // Save the tokens and expiration
            const { refresh_token, refresh_token_expires_in, access_token, expires_in } = res.data;
            localStorage.setItem(TD_REFRESH_TOKEN, refresh_token);
            localStorage.setItem(
                TD_REFRESH_TOKEN_EXPIRATION,
                moment().add(refresh_token_expires_in, 'seconds').toDate().getTime().toString(),
            );
            localStorage.setItem(TD_ACCESS_TOKEN, access_token);
            localStorage.setItem(
                TD_ACCESS_TOKEN_EXPIRATION,
                moment().add(expires_in, 'seconds').toDate().getTime().toString(),
            );
            return refresh_token;
        } catch (ex) {
            localStorage.removeItem(TD_CODE);
            return null;
        }
    }

    getLoginUrl() {
        return `https://auth.tdameritrade.com/auth?response_type=code&redirect_uri=${encodeURIComponent(
            this.redirectUri,
        )}&client_id=${this.consumerKey}%40AMER.OAUTHAP`;
    }

    getCode() {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code') || localStorage.getItem(TD_CODE);
        if (code) {
            localStorage.setItem(TD_CODE, code);
        }
        return code;
    }

    private get clientId() {
        return `${this.consumerKey}@AMER.OAUTHAP`;
    }
}
