import create from 'zustand';
import _ from 'lodash';
import { Options } from './components/Options';
import { Order, Quote, Trade } from './api/tosClient';

interface AppStore {
    quotes: Quote[];
    orders: Order[];
    trades: Trade[];
    options: Options;
    dates: [Date, Date] | null;
    watchlist: string[];
    todaysSymbols: string[];
    setDates: (date: [Date, Date] | null) => void;
    setOptions: (options: Options) => void;
    setWatchlist: (watchlist: string[]) => void;
}

type SetStore = (
    partial: AppStore | Partial<AppStore> | ((state: AppStore) => AppStore | Partial<AppStore>),
    replace?: boolean | undefined,
) => void;

function getInitialStore() {
    // Load options
    const options = JSON.parse(localStorage.getItem('options') as string) || {
        profit: 'positionSize',
        plRatio: 'percent',
        currencyDecimals: '2',
        maxPosition: null,
    };

    // Load dates
    const strDates: [string, string] | null = JSON.parse(localStorage.getItem('dates') as string)?.dates;
    const dates = strDates ? (strDates.map((str) => new Date(str)) as [Date, Date]) : null;

    // Load watchlist
    const watchlist = JSON.parse(localStorage.getItem('watchlist') as string)?.watchlist || [];
    return { quotes: [], orders: [], trades: [], halts: [], watchlist, todaysSymbols: [], options, dates };
}

function getActions(set: SetStore) {
    return {
        setDates: (dates: [Date, Date] | null) => {
            localStorage.setItem('dates', JSON.stringify({ dates }));
            set(() => ({ dates }));
        },
        setOptions: (options: Options) => {
            localStorage.setItem('options', JSON.stringify(options));
            set(() => ({ options }));
        },
        setWatchlist: (watchlist: string[]) => {
            const uniqueWatchlist = _.uniq(watchlist).map((s) => s.toUpperCase());
            localStorage.setItem('watchlist', JSON.stringify({ watchlist: uniqueWatchlist }));
            set(() => ({ watchlist: uniqueWatchlist }));
        },
    };
}

export const useAppStore = create<AppStore>((set) => ({ ...getInitialStore(), ...getActions(set) }));
