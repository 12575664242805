import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { formatPercent, profitPercent } from '../metrics';
import { useDisclosure } from '@chakra-ui/hooks';
import { Button } from '@chakra-ui/button';
import { Badge } from '@chakra-ui/layout';
import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/popover';
import { useAppStore } from '../store';

export function CalendarFilter() {
    const dates = useAppStore((state) => state.dates);
    const trades = useAppStore((state) => state.trades);
    const options = useAppStore((state) => state.options);
    const onChange = useAppStore((state) => state.setDates);

    const { isOpen, onToggle, onClose } = useDisclosure();
    let dateString = 'Filter by dates';

    if (dates) {
        const startString = moment(dates[0]).format('MMM Do, YYYY');
        const endString = moment(dates[1]).format('MMM Do, YYYY');
        if (startString === endString) {
            dateString = startString;
        } else {
            dateString = `${startString} - ${endString}`;
        }
    }
    const button = (
        <Button size="sm" colorScheme="green" key="calendar-button" onClick={onToggle}>
            {dateString}
            <Badge
                ml="1"
                textTransform="none"
                colorScheme="blue"
                onClick={(e) => {
                    e.stopPropagation();
                    onChange(null);
                    onClose();
                }}
            >
                Reset
            </Badge>
        </Button>
    );

    const tile = (args: any) => {
        const { date, view } = args;
        const start = date.getTime();
        let end: number;
        if (view === 'decade') {
            end = moment(date)
                .year(moment(date).year() + 1)
                .toDate()
                .getTime();
        } else if (view === 'year') {
            end = moment(date)
                .month(moment(date).month() + 1)
                .toDate()
                .getTime();
        } else if (view === 'month') {
            end = moment(date).add(1, 'day').toDate().getTime();
        }

        const profit = profitPercent(
            trades.filter((t) => t.date.getTime() > start && t.date.getTime() < end),
            options.profit,
        );
        if (profit > 0 || profit < 0) {
            return (
                <Badge display="block" colorScheme={profit > 0 ? 'green' : 'red'} size="xs">
                    {formatPercent(profit)}
                </Badge>
            );
        }
        return null;
    };

    const calendar = (
        <Calendar
            key="calendar"
            selectRange={true}
            maxDate={new Date()}
            onChange={(date: unknown) => {
                onChange(date as [Date, Date]);
                onClose();
            }}
            className="stonk"
            value={dates}
            tileContent={tile}
            tileClassName={(args: any) => {
                const { date, view } = args;
                const start = date.getTime();
                let end: number;
                if (view === 'decade') {
                    end = moment(date)
                        .year(moment(date).year() + 1)
                        .toDate()
                        .getTime();
                } else if (view === 'year') {
                    end = moment(date)
                        .month(moment(date).month() + 1)
                        .toDate()
                        .getTime();
                } else if (view === 'month') {
                    end = moment(date).add(1, 'day').toDate().getTime();
                }

                const profit = profitPercent(
                    trades.filter((t) => t.date.getTime() > start && t.date.getTime() < end),
                    options.profit,
                );
                if (profit > 0) {
                    return 'green';
                } else if (profit < 0) {
                    return 'red';
                }
                return null;
            }}
        />
    );

    return (
        <Popover isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger>{button}</PopoverTrigger>
            <PopoverContent w={500}>{calendar}</PopoverContent>
        </Popover>
    );
}
