import moment from 'moment';
import _ from 'lodash';
import { mapOrdersToTrades, tosClient } from './api/tosClient';
import { useAppStore } from './store';

const GENERAL_UPDATE_FREQUENCY = 3000;
let interval: any;

export async function start() {
    await updateTodaysOrders();
    await updateQuotes();
    getOrderHistory();
    interval =
        interval ||
        setInterval(() => {
            updateTodaysOrders();
            updateQuotes();
        }, GENERAL_UPDATE_FREQUENCY);
}

async function updateQuotes() {
    const { watchlist, todaysSymbols } = useAppStore.getState();
    const quotes = await tosClient.getQuotes([...watchlist, ...todaysSymbols]);
    useAppStore.setState({ quotes });
}

async function getOrderHistory() {
    for (let i = 0; i < 12; i++) {
        const newOrders = await tosClient.getOrders(30, i * 30);
        const orders = mergeByOrderId(useAppStore.getState().orders, newOrders);
        const newTrades = mapOrdersToTrades(newOrders);
        const trades = mergeByOrderId(useAppStore.getState().trades, newTrades);
        useAppStore.setState({ orders, trades });
    }
}

async function updateTodaysOrders() {
    // Merge in new orders
    const updatedOrders = await tosClient.getOrders(1);
    const orders = mergeByOrderId(useAppStore.getState().orders, updatedOrders);

    // Merge in new trades
    const updatedTrades = mapOrdersToTrades(updatedOrders);
    const trades = mergeByOrderId(useAppStore.getState().trades, updatedTrades);

    // Get today's symbols
    const todaysSymbols = _.uniq(
        updatedTrades.filter((t) => moment().diff(moment(t.date), 'day') <= 1).map((t) => t.symbol),
    );

    // Save to state
    useAppStore.setState({ orders, trades, todaysSymbols });
}

function mergeByOrderId<T extends { orderId: number }>(current: T[], updates: T[]): T[] {
    const list = [...current];
    updates.forEach((update) => {
        const i = list.findIndex((existing) => update.orderId === existing.orderId);
        if (i !== -1) {
            list[i] = update;
        } else {
            list.push(update);
        }
    });
    return list;
}
