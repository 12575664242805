import React, { useState } from 'react';
import { Trade } from '../api/tosClient';
import {
    formatCurrency,
    formatDateTime,
    formatPercent,
    holdTime,
    profitPercent,
    profitAmount,
    averagePrice,
    holdTimeString,
} from '../metrics';
import { useAppStore } from '../store';

import { TableContainer, Table, Thead, Tr, Td, Tbody, Th } from '@chakra-ui/table';
import { Text } from '@chakra-ui/react';

import _ from 'lodash';

const monospace = '"Lucida Console", Courier, monospace';

export function getPositionShares(positionTrades: Trade[]) {
    return positionTrades.reduce((qty, t) => qty + (t.side === 'SELL' ? -1 : 1) * t.qty, 0);
}

export function getPositions(trades: Trade[], includeOpenPositions = false) {
    const groupedBySymbol = _.groupBy(trades, (t) => t.symbol);
    const positions: Trade[][] = [];

    Object.keys(groupedBySymbol).forEach((symbol) => {
        const symbolTrades = groupedBySymbol[symbol].sort((a, b) => a.date.getTime() - b.date.getTime());

        let positionTrades: Trade[] = [];
        // Closed positions
        symbolTrades.forEach((trade) => {
            positionTrades.push(trade);
            const positionShares = getPositionShares(positionTrades);
            if (positionShares === 0) {
                positions.push(positionTrades);
                positionTrades = [];
            }
        });

        // Open position
        if (includeOpenPositions && positionTrades.length) {
            positions.push(positionTrades);
        }
    });

    return positions;
}

function sortPosition(sortBy: string, ascending: boolean, a: Trade[], b: Trade[]) {
    let value: number;
    if (sortBy === 'Symbol') {
        value = a[0].symbol.localeCompare(b[0].symbol);
    } else if (sortBy === 'Open') {
        value = a[0].date.getTime() - b[0].date.getTime();
    } else if (sortBy === 'Close') {
        value = a[a.length - 1].date.getTime() - b[b.length - 1].date.getTime();
    } else if (sortBy === 'Duration') {
        value = holdTime(a) - holdTime(b);
    } else if (sortBy === 'Profit ($)') {
        value = profitAmount(a) - profitAmount(b);
    } else if (sortBy === 'Profit (%)') {
        value = profitPercent(a) - profitPercent(b);
    } else {
        value = 0;
    }

    if (!ascending) {
        return value * -1;
    }
    return value;
}

export function getFilteredTrades(trades: Trade[], dates: [Date, Date] | null) {
    return trades.filter(
        (t) => dates === null || (t.date.getTime() > dates[0].getTime() && t.date.getTime() < dates[1].getTime()),
    );
}

export function PositionsTable() {
    const [sortBy, setSortBy] = useState('Open');
    const [ascending, setAscending] = useState(false);
    const options = useAppStore((state) => state.options);
    const trades = useAppStore((state) => state.trades);
    const dates = useAppStore((state) => state.dates);

    const filteredTrades = getFilteredTrades(trades, dates);

    function onSort(newSortBy: string) {
        setSortBy(newSortBy);
        setAscending(sortBy === newSortBy ? !ascending : false);
    }

    const currencyDecimals = parseInt(options.currencyDecimals);
    const positions = getPositions(filteredTrades, true).sort((a, b) => sortPosition(sortBy, ascending, a, b));

    return (
        <TableContainer>
            <Table variant="striped" size="sm" colorScheme="cyan">
                <Thead>
                    <Tr>
                        {[
                            'Symbol',
                            'Open',
                            'Close',
                            'Duration',
                            'Shares',
                            'Buy Price',
                            'Sell Price',
                            'Profit ($)',
                            'Profit (%)',
                        ].map((name) => (
                            <Th key={name} className={'sortable'} onClick={() => onSort(name)}>
                                {name}
                                <span className={sortBy === name ? (ascending ? 'up' : 'down') : 'none'}>&uarr;</span>
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {positions.map((trades, i) => {
                        const open = trades[0];
                        const close = trades[trades.length - 1];

                        const buys = trades.filter((t) => t.side === 'BUY');
                        const sells = trades.filter((t) => t.side === 'SELL');

                        const avgBuyPrice = averagePrice(buys);
                        const avgSellPrice = averagePrice(sells);

                        const shares = buys.reduce((sum, t) => sum + t.qty, 0);
                        const isOpen = getPositionShares(trades) > 0;

                        return (
                            <Tr key={i}>
                                <Td>{open.symbol}</Td>
                                <Td>
                                    <Text fontSize="xs">{formatDateTime(open.date)}</Text>
                                </Td>
                                <Td>
                                    <Text fontSize="xs">{formatDateTime(close.date)}</Text>
                                </Td>
                                <Td isNumeric>
                                    <Text fontSize="xs">{holdTimeString(trades)}</Text>
                                </Td>
                                <Td fontFamily={monospace} isNumeric>
                                    {shares}
                                </Td>
                                <Td fontFamily={monospace} isNumeric>
                                    {formatCurrency(avgBuyPrice, currencyDecimals)}
                                </Td>
                                <Td fontFamily={monospace} isNumeric>
                                    {isOpen ? '--' : formatCurrency(avgSellPrice, currencyDecimals)}
                                </Td>
                                <Td fontFamily={monospace} isNumeric>
                                    {isOpen ? '--' : formatCurrency(profitAmount(trades), currencyDecimals)}
                                </Td>
                                <Td fontFamily={monospace} isNumeric>
                                    {isOpen ? '--' : formatPercent(profitPercent(trades))}
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </TableContainer>
    );
}
