import React from 'react';

import { TableContainer, Table, Tr, Td, Tbody } from '@chakra-ui/table';

import { round } from '../utils';
import {
    accuracy,
    averagePositionSize,
    formatCurrency,
    formatPercent,
    getWinsAndLosses,
    plRatio,
    profitAmount,
    profitPercent,
} from '../metrics';
import { useAppStore } from '../store';
import { getFilteredTrades } from './PositionsTable';

const monospace = '"Lucida Console", Courier, monospace';

export function PerformanceTable() {
    const dates = useAppStore((state) => state.dates);
    const trades = useAppStore((state) => state.trades);
    const options = useAppStore((state) => state.options);

    const filteredTrades = getFilteredTrades(trades, dates);

    return (
        <TableContainer>
            <Table size="sm" colorScheme="cyan" variant="striped">
                <Tbody>
                    <Tr>
                        <Td fontWeight={500}>Profit ($)</Td>
                        <Td fontFamily={monospace} isNumeric>
                            {formatCurrency(profitAmount(filteredTrades), 2)}
                        </Td>
                        <Td fontWeight={500}>Profit (%)</Td>
                        <Td fontFamily={monospace} isNumeric>
                            {formatPercent(profitPercent(filteredTrades, options.profit))}
                        </Td>
                        <Td fontWeight={500}>P/L Ratio </Td>
                        <Td fontFamily={monospace} isNumeric>
                            {round(plRatio(filteredTrades, options.plRatio), 2) || '--'}
                        </Td>
                        <Td fontWeight={500}>Average Position Size ($) </Td>
                        <Td fontFamily={monospace} isNumeric>
                            {formatCurrency(averagePositionSize(filteredTrades) as number, 2)}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td fontWeight={500}>Wins </Td>
                        <Td fontFamily={monospace} isNumeric>
                            {getWinsAndLosses(filteredTrades).numWon}
                        </Td>
                        <Td fontWeight={500}>Losses </Td>
                        <Td fontFamily={monospace} isNumeric>
                            {getWinsAndLosses(filteredTrades).numLost}
                        </Td>
                        <Td fontWeight={500}>Accuracy (%)</Td>
                        <Td fontFamily={monospace} isNumeric>
                            {formatPercent(accuracy(filteredTrades))}
                        </Td>
                        <Td fontWeight={500}></Td>
                        <Td fontFamily={monospace} isNumeric></Td>
                    </Tr>
                </Tbody>
            </Table>
        </TableContainer>
    );
}
