import React from 'react';
import { tosClient } from '../api/tosClient';
import { Spinner } from '@chakra-ui/spinner';
import { Main } from './Main';
import { Box, Center, Code, Flex, Heading, Link, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { ReactComponent as TdAmeritradeLogo } from '../logos/td.svg';
import { Input } from '@chakra-ui/input';
interface State {
    isReady: boolean;
    hasAccessToken: boolean;
}

export class Home extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = { isReady: false, hasAccessToken: false };
    }

    async componentDidMount() {
        const hasAccessToken = await tosClient.init;
        this.setState({ isReady: true, hasAccessToken });
    }

    render() {
        if (!this.state.isReady) {
            return (
                <Center p={10} flexDirection="column">
                    <Heading size="xl">Checking TD Ameritrade credentials</Heading>
                    <Box p={10}>
                        <Spinner />
                    </Box>
                </Center>
            );
        } else if (this.state.hasAccessToken) {
            return <Main />;
        } else if (hasTdConsumerKey()) {
            return <Login />;
        } else {
            return <TdAmeritradeSetup />;
        }
    }
}

const TdAmeritradeSetup = () => {
    const [value, setValue] = React.useState(localStorage.getItem('tdConsumerKey') || '');

    const onSubmit = () => {
        if (value) {
            localStorage.setItem('tdConsumerKey', value);
            window.location.href = tosClient.getLoginUrl();
        }
    };

    return (
        <Center p={10} flexDirection="column">
            <Box w={400}>
                <Flex>
                    <TdAmeritradeLogo height={64.5} width={184.5} />
                    <Heading size="md" mt={4}>
                        Setup TD Ameritrade
                    </Heading>
                </Flex>
                <Text m={2} size="xl">
                    Create a TD Ameritrade API app with a redirect URL of <Code>{window.location.origin}</Code>. Then,
                    paste your Consumer Key below and log in.
                </Text>
                <Input
                    m={2}
                    w={264}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder="Consumer Key"
                />
                <Button colorScheme="green" top={-0.5} onClick={onSubmit}>
                    Log in
                </Button>
            </Box>
        </Center>
    );
};

function hasTdConsumerKey() {
    return !!localStorage.getItem('tdConsumerKey');
}

export const Login = () => {
    return (
        <Center p={10} flexDirection="column">
            <Heading size="xl">Log in</Heading>
            <Text mt={2} size="xl">
                Choose your broker to continue
            </Text>
            <Box p={10}>
                <Link href={tosClient.getLoginUrl()}>
                    <Button variant="outline">
                        <TdAmeritradeLogo height={64.5} width={184.5} />
                    </Button>
                </Link>
            </Box>
        </Center>
    );
};
